import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Einrichtung Karriereportal Agent",
  "description": "Um die Kommunikation zwischen Portal und Business Cenrtal / Business Central zu aktivieren benötigen wir den Karriereportal Agent",
  "author": "Jochen Ritscher",
  "categories": ["businesscentral"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Dieser wird auf dem Business Central-App-Server oder auf einem Ihrer Anwendungsserver im Netzwerk eingerichtet.`}</p>
    <p>{`Der API-Zugang zum Portal ist im gelieferten Kundenpaket bereits hinterlegt, der Business Central Web Service Pfad und Port muss gemäß `}<a parentName="p" {...{
        "href": "https://msdn.microsoft.com/dede/"
      }}>{`https://msdn.microsoft.com/dede/`}</a>{`
library/dd355055(v=nav.90).aspx#SOAPServices hinterlegt werden. `}</p>
    <p>{`Die SOAP Web Services müssen aktiviert sein (Einstellung „Enable SOAP Services“).`}</p>
    <p>{`Anschließend sind die Tasks vorbereitet, diese werden üblicherweise über die Windows Server Aufgabenplanung als periodisch laufender Task eingerichtet (z.B. 15-Minuten-Takt) und laufen unter einem eigenen Active Directory Benutzeraccount.`}</p>
    <p>{`Dieser benötigt folgende Rechte:`}</p>
    <ul>
      <li parentName="ul">{`Zugriff auf Business Central
Ausführen der Page und Codeunit Web Services, Lese- und Schreibzugriff auf die POM CP Tabellen`}</li>
      <li parentName="ul">{`Zugriff auf das Ereignislog`}</li>
      <li parentName="ul">{`Einstellung „Anmelden als Stapelverarbeitungsauftrag“ in den Lokalen Richtlinien `}<a parentName="li" {...{
          "href": "https://technet.microsoft.com/de-de/library/dn221944(v=ws.11).aspx"
        }}>{`https://technet.microsoft.com/de-de/library/dn221944(v=ws.11).aspx`}</a></li>
    </ul>
    <img src="/images/settings/agent01.png" alt="agent01.png" style={{
      "width": "100%"
    }} />
    <h2>{`Aufgabe erstellen`}</h2>
    <img src="/images/settings/agent02.png" alt="agent02.png" style={{
      "width": "100%"
    }} />
    <ul>
      <li parentName="ul">{`Name festlegen`}</li>
      <li parentName="ul">{`„Unabhängig von der Benutzeranmeldung ausführen“ auswählen und Dienstbenutzer mit Passwort hinterlegen`}</li>
    </ul>
    <img src="/images/settings/agent03.png" alt="agent01" style={{
      "width": "100%"
    }} />
    <ul>
      <li parentName="ul">{`Aufgabentrigger mit Zeitplan festlegen`}</li>
      <li parentName="ul">{`Empfehlung: Wöchentlich, Mo-Fr,`}</li>
      <li parentName="ul">{`Wiederholen je 15 Minuten, Aufgabe beenden nach 8 Stunden, Aktiviert`}</li>
    </ul>
    <img src="/images/settings/agent04.png" alt="agent04" style={{
      "width": "100%"
    }} />
    <p>{`Als Aktion das Programm SyncApplicants.exe starten lassen.`}</p>
    <p>{`Diese Schritte Analog auch für den Task „PostJobs“ ausführen.`}</p>
    <h2>{`Einrichtung des Fileshares`}</h2>
    <p>{`Zum Ablegen der hochgeladenen Bewerberdokumente wird ein Fileshare benötigt. Hierauf benötigt der Dienstbenutzer Schreibzugriff um die Dokumente periodisch herunterzuladen und abzulegen.`}</p>
    <p>{`Berechtigte Mitarbeiter benötigen Lesezugriff. In Dynamics Business Central selbst werden lediglich die Dokumentenpfade abgelegt.`}</p>
    <p>{`Es werden nur UNC-Pfade unterstützt, kein Netzlaufwerke.`}</p>
    <p>{`Dokumente können auch an ein Dokumenten-Managementsystem angeliefert werden.`}</p>
    <p>{`Bitte nehmen Sie hierzu Kontakt zum technischen Support auf.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      